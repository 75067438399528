import { markAnimationComplete } from './animationObserver'

export const ifThemeEditor = (loader) => {
	if (Shopify.designMode) {
		console.log('we are in shopify design mode')
		document.addEventListener('shopify:section:load', (e) => {
			if (!e.target) return
			const video = e.target.querySelectorAll('video.lazyload:not([data-loaded="true"])')
			if (video && video.length) {
				for (let i = 0; i < video.length; i++) {
					video[i].setAttribute('autoplay', '')
				}
			}
			const unloaded = e.target.querySelectorAll('.lazyload:not([data-loaded="true"])')
			if (!unloaded || !unloaded.length) return
			for (let i = 0; i < unloaded.length; i++) {
				loader.triggerLoad(unloaded[i])
			}
			const animations = e.target.querySelectorAll('[data-animate-section]:not(.section-animated)')
			animations.forEach((animation) => {
				animation.classList.add('section-animated')
				markAnimationComplete(animation)
			})
		})
	}
}

import { initialize } from '../scripts/tss/initialize'
import lozad from 'lozad'
import { isElementVisible } from '../scripts/tss/helpers/isElementVisible'
import { videoObserver } from '../scripts/tss/helpers/videoObserver'
import { animationObserver } from '../scripts/tss/helpers/animationObserver'
import { ifThemeEditor } from '../scripts/tss/helpers/themeEditor'
import Tripleseat from '../scripts/tss/helpers/Tripleseat'
import { initResizeListeners } from '../scripts/tss/helpers/initResizeListeners'
import {setExternalLinks} from "../scripts/tss/helpers/global";

const loadAssets = () => {
    const observer = lozad('.lazyload', {
        rootMargin: '10px 0px', // syntax similar to that of CSS Margin
        threshold: 0.1, // ratio of element convergence
    }) // passing a `NodeList` (e.g. `document.querySelectorAll()`) is also valid
    observer.observe()
    // start loading image assets after a few seconds (let the page load first)

    window.setTimeout(() => {
        const unloaded = document.querySelectorAll('img.lazyload:not([data-loaded="true"])')
        for (let i = 0; i < unloaded.length; i++) {
            if (!isElementVisible(unloaded[i])) continue
            window.setTimeout(() => {
                observer.triggerLoad(unloaded[i])
            }, parseInt(i) * 100)
        }
    }, 2500)
    // wait another 5 seconds and start loading footer and quiz vids
    window.setTimeout(() => {
        const unloadedVideo = document.querySelectorAll('video.lazyload:not([data-loaded="true"])')
        for (let i = 0; i < unloadedVideo.length; i++) {
            if (!isElementVisible(unloadedVideo[i])) continue
            window.setTimeout(() => {
                observer.triggerLoad(unloadedVideo[i])
            }, parseInt(i) * 300)
        }
    }, 6000)
    // play videos when in view and pause when not in view
    videoObserver(observer)
    // immediately load images in theme editor
    ifThemeEditor(observer)
}

document.addEventListener(
    'DOMContentLoaded',
    function () {
        setExternalLinks()
        initResizeListeners()
        // tripleseat init
        window.uc = {
            tripleseat: new Tripleseat(),
        }
        // initialize custom elements
        initialize()
        // instantiate lozad
        loadAssets()
        // accessibility, focus anchor links
        document.addEventListener('keyup', (event) => {
            if (event?.keyCode !== 9 && event?.code !== 'Tab') return
            document.body.classList.add('a11y')
        })
        // animation observer
        animationObserver()
    },
    false
)

import { getFocusableElements, removeTrapFocus, trapFocus } from '../helpers/trapFocus'

export class StickyHeader extends HTMLElement {
	constructor() {
		super()
		this.connectedCallback.bind(this)
	}

	connectedCallback() {
		this.container = document.querySelector('.header-group-container')
		this.observable = document.querySelector('#observable')
		this.announcement = document.querySelector('.announcement-bar')
		this.createObserver()
		this.firstLoad()
		this.megaMenus = {}
		this.initMegaMenus()
	}

	firstLoad() {
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop
		if (scrollTop > 0) {
			this.container.classList.add('scrolled')
		}
	}

	createObserver() {
		let observer = new IntersectionObserver((entries, observer) => {
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop
			if (this.announcement) {
				const announcementHeight = getComputedStyle(document.documentElement).getPropertyValue('--bar-height').replace('px', '')
				scrollTop -= announcementHeight
			}
			if (scrollTop > 0) {
				this.container.classList.add('scrolled')
				this.container.classList.add('animated-once')
			} else {
				this.container.classList.remove('scrolled')
			}
		})

		observer.observe(this.observable)
	}

	initMegaMenus() {
		const megaMenus = this.container.querySelectorAll('[data-mega-menu]')
		megaMenus.forEach((el) => {
			const key = el.getAttribute('data-mega-menu')
			const megaMenu = {
				el,
				key,
				trigger: this.container.querySelector(`.header__menu [data-mega-trigger=${key}]`),
				mobileTrigger: this.container.querySelector(`.mobile-nav [data-mega-trigger=${key}]`),
				mobileBack: el.querySelector('.mega-menu__mobile-close'),
				close: el.querySelector('[data-close]'),
			}

			if (!megaMenu.trigger) return

			// accesibility
			megaMenu.trigger.setAttribute('aria-expanded', 'false')
			megaMenu.trigger.setAttribute('aria-label', `Open ${key} Menu`)
			megaMenu.trigger.setAttribute('role', 'button')

			// handlers
			megaMenu.handleClose = () => {
				this.closeMegaMenu(key)
			}

			megaMenu.handleKeyClose = (event) => {
				if (event?.code?.toUpperCase() !== 'ESCAPE') return
				this.closeMegaMenu(key)
			}

			megaMenu.trigger.addEventListener('mouseenter', () => {
				this.openMegaMenu(key)
			})

			megaMenu.trigger.addEventListener('keydown', (e) => {
				if (e?.keyCode === 13 || e?.key === 'Enter') {
					e.preventDefault()
					this.openMegaMenu(key)
				}
			})

			if (megaMenu.mobileTrigger) {
				megaMenu.mobileTrigger.setAttribute('aria-expanded', 'false')
				megaMenu.mobileTrigger.setAttribute('aria-label', `Open ${key} Menu`)
				megaMenu.mobileTrigger.setAttribute('role', 'button')

				megaMenu.mobileTrigger.addEventListener('click', (e) => {
					e.preventDefault()
					this.openMegaMenu(key)
				})
			}

			if (megaMenu.mobileBack) {
				megaMenu.mobileBack.addEventListener('click', (e) => {
					e.preventDefault()
					megaMenu.handleClose()
				})
			}

			this.megaMenus[key] = megaMenu
			const mobileNav = document.querySelector('mobile-nav')
			if (!mobileNav) return;
			const links = el.querySelectorAll('a')
			links.forEach((link) => {
				link.addEventListener('click', () => {
					mobileNav.toggleMenu()
				})
			})
		})
	}

	openMegaMenu(key) {
		const currentMenu = this.container.getAttribute('data-open-mega')
		if (currentMenu === key) return // select menu has not changed
		if (currentMenu) this.closeMegaMenuNoAnimation(currentMenu) // avoid animation flash

		// accessibility - order is important here to make the animations clear
		this.megaMenus[key].el.setAttribute('aria-hidden', 'false')
		this.container.setAttribute('data-open-mega', key)
		this.megaMenus[key].trigger.setAttribute('aria-expanded', 'true')
		if (this.megaMenus[key].mobileTrigger) this.megaMenus[key].mobileTrigger.setAttribute('aria-expanded', 'true')

		const focusableEls = getFocusableElements(this.megaMenus[key].el)
		if (focusableEls.length) trapFocus(this.megaMenus[key].el, focusableEls[0])

		this.megaMenus[key].close.addEventListener('mouseenter', this.megaMenus[key].handleClose)
		document.addEventListener('keyup', this.megaMenus[key].handleKeyClose)

		// timeout display none for accessibility
		if (window.closeMegaMenuTimeout) clearTimeout(window.closeMegaMenuTimeout)
		window.openMegaMenuTimeout = window.setTimeout(() => {
			this.container.setAttribute('data-is-open', '')
		}, 500)
	}

	closeMegaMenuNoAnimation(key) {
		this.megaMenus[key].trigger.setAttribute('aria-expanded', 'false')
		this.megaMenus[key].mobileTrigger.setAttribute('aria-expanded', 'false')
		removeTrapFocus(this.megaMenus[key].trigger)
		this.megaMenus[key].close.removeEventListener('mouseenter', this.megaMenus[key].handleClose)
		document.removeEventListener('keyup', this.megaMenus[key].handleKeyClose)
	}

	closeMegaMenu(key) {
		this.closeMegaMenuNoAnimation(key)
		this.container.removeAttribute('data-open-mega')
		this.container.removeAttribute('data-is-open')
		// wait until end of animation to set aria-hidden to true - CSS sets el to display none for accessibility
		// we are also using aria-hidden to determine whether modals are open to prevent glitchy animation when switch between megamenus

		if (window.openMegaMenuTimeout) clearTimeout(window.openMegaMenuTimeout)
		window.closeMegaMenuTimeout = window.setTimeout(() => {
			Object.keys(this.megaMenus).forEach((key) => {
				this.megaMenus[key].el.setAttribute('aria-hidden', 'true')
			})
		}, 500)
	}

	externalCloseAll() {
		Object.keys(this.megaMenus).forEach((key) => {
			this.megaMenus[key].handleClose()
		})
	}
}

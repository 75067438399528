import { CartItems } from './CartItems'

export class CartDrawerItems extends CartItems {
	getSectionsToRender() {
		return [
			{
				id: 'CartDrawer',
				section: 'cart-drawer',
				selector: 'cart-drawer-items',
			},
			{
				id: 'CartDrawer',
				section: 'cart-drawer',
				selector: '.cart-drawer__ctas',
			},
			{
				id: 'cart-icon-bubble',
				section: 'cart-icon-bubble',
				selector: '.shopify-section',
			},
		]
	}
}

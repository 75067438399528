export class PriceRange extends HTMLElement {
	constructor() {
		super()
		this.querySelectorAll('input').forEach((element) => element.addEventListener('change', this.onRangeChange.bind(this)))
		this.setMinAndMaxValues()
	}

	onRangeChange(event) {
		this.adjustToValidValues(event.currentTarget)
		this.setMinAndMaxValues()
	}

	setMinAndMaxValues() {
		const inputs = this.querySelectorAll('input')
		const minInput = inputs[0]
		const maxInput = inputs[1]
		if (maxInput.value) minInput.setAttribute('max', maxInput.value)
		if (minInput.value) maxInput.setAttribute('min', minInput.value)
		if (minInput.value === '') maxInput.setAttribute('min', 0)
		if (maxInput.value === '') minInput.setAttribute('max', maxInput.getAttribute('max'))
	}

	adjustToValidValues(input) {
		const value = Number(input.value)
		const min = Number(input.getAttribute('min'))
		const max = Number(input.getAttribute('max'))

		if (value < min) input.value = min
		if (value > max) input.value = max
	}
}

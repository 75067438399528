export class FacetRemove extends HTMLElement {
	constructor() {
		super()
		const facetLink = this.querySelector('a')
		facetLink.setAttribute('role', 'button')
		facetLink.addEventListener('click', this.closeFilter.bind(this))
		facetLink.addEventListener('keyup', (event) => {
			event.preventDefault()
			if (event.code.toUpperCase() === 'SPACE') this.closeFilter(event)
		})
	}

	closeFilter(event) {
		event.preventDefault()
		const form = this.closest('facet-filters-form') || document.querySelector('facet-filters-form')
		form.onActiveFilterClick(event)
	}
}

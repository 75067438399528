export class CartItem extends HTMLElement {
	constructor() {
		super()

		this.data = this.initialize.bind(this)
	}

	initialize() {}
	empty() {
		return {
			key: '',
			index: 0,
			currentQuantity: 0,
			updateQuantity: 0,
			variantId: '',
			associatedBundles: [],
			isSubscription: false,
			price: 0,
		}
	}
	receiveUpdate() {}
	sendData() {
		return this.data
	}
}

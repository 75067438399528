export class MobileNav extends HTMLElement {
	constructor() {
		super()
		this.connectedCallback.bind(this)
	}

	connectedCallback() {
		this.el = this.querySelector('.mobile-nav')
		this.body = document.querySelector('body')
		this.toggleButtons = document.querySelectorAll('[data-toggle-menu]')
		this.toggleButtons.forEach((button) => {
			button.addEventListener('click', (e) => {
				e.preventDefault()
				this.toggleMenu()
			})
		})
		this.stickyHeader = document.querySelector('sticky-header')
	}

	toggleMenu() {
		document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight}px`)
		this.body.classList.toggle('mobile-menu-open')
		this.el.classList.toggle('mobile-menu-open')
		this.stickyHeader.externalCloseAll()
	}
}

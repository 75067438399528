import { styledSelect } from './styledSelect'

export default class Tripleseat {
	constructor() {
		this.forms = {}
	}

	async initializeForm(sectionId) {
		const form = await this.waitForLoad(`[data-tripleseat="${sectionId}"] #tripleseat_embed_form`)
		if (!form) return
		// init with key in case there's multiple forms in a page
		this.forms[sectionId] = {
			el: form,
			container: document.querySelector(`[data-tripleseat="${sectionId}"]`),
			inputs: form.querySelectorAll('input:not([type="hidden"]):not([type="submit"])'),
			selects: form.querySelectorAll('select'),
			timeInputs: form.querySelectorAll('input.timeselect_input'),
			submitButton: form.querySelector('input[type="submit"]'),
			datePicker: form.querySelector('#ui-datepicker-div'),
		}
		// basic dom updates
		this.forms[sectionId].inputs.forEach((input) => {
			input.setAttribute('autocomplete', 'off')
			this.replaceLabel(this.forms[sectionId].el, input)
		})
		if (this.forms[sectionId].submitButton) {
			this.forms[sectionId].submitButton.value = 'Book The Space'
		}
		// unhide now that it's lodaded
		this.forms[sectionId].container.setAttribute('data-loaded', 'true')
		// replace default selects
		this.forms[sectionId].selects.forEach((select) => {
			this.replaceLabel(this.forms[sectionId].el, select)
			styledSelect(`#${select.id}`)
		})

		this.setDateSelects(sectionId)

		const resizeObserver = new ResizeObserver((entries) => this.onResize())
		resizeObserver.observe(this.forms[sectionId].el)
	}

	waitForLoad(selector) {
		return new Promise((resolve) => {
			if (document.querySelector(selector)) {
				resolve(document.querySelector(selector))
			}

			const observer = new MutationObserver((mutations) => {
				if (document.querySelector(selector)) {
					resolve(document.querySelector(selector))
					observer.disconnect()
				}
			})

			observer.observe(document.body, {
				childList: true,
				subtree: true,
			})
		})
	}

	waitForUnload(selector, child) {
		return new Promise((resolve) => {
			const el = document.querySelector(selector)

			if (!el.querySelector(child)) {
				return resolve(document.querySelector(selector))
			}

			const observer = new MutationObserver(function (mutations) {
				mutations.forEach(function (mutation) {
					if (mutation.type === 'childList' && mutation.removedNodes.length) {
						resolve(document.querySelector(selector))
						observer.disconnect()
					}
				})
			})

			observer.observe(el, {
				childList: true,
				subtree: true,
			})
		})
	}

	replaceLabel(form, input) {
		if (!form) return
		const label = form.querySelector(`[for="${input.id}"]`)
		if (!label) return
		// loop to get rid of html elements inside label
		let child = label.lastElementChild
		while (child) {
			label.removeChild(child)
			child = label.lastElementChild
		}
		input.setAttribute('placeholder', label.innerHTML)
		// replace default select text
		const firstOption = input.querySelector('option')
		if (firstOption) firstOption.innerHTML = label.innerHTML
		label.classList.add('visually-hidden')
	}

	onResize() {
		// make sure time selects are same width as related input
		let timeInputIndex = 0
		const timeSelects = document.querySelectorAll('.timeselect')
		Object.keys(this.forms).forEach((key) => {
			this.forms[key].timeInputs.forEach((timeInput) => {
				if (timeSelects.length <= timeInputIndex) return
				const width = timeInput.getBoundingClientRect().width
				timeSelects[timeInputIndex].style.width = `${width}px`
				timeInputIndex++
			})
		})

		// clip height & add 'continue' button for longer forms
		Object.keys(this.forms).forEach((key) => {
			if (this.forms[key].clickedContinue) return

			const headings = this.forms[key].el.querySelectorAll('tr h2')

			if (headings.length < 2) return
			const trs = this.forms[key].el.querySelectorAll('tr')
			let location = 0
			trs.forEach((tr, index) => {
				if (tr.querySelector('[colspan="2"] > h2') && location === 0 && index !== 0) {
					location = index - 1
				}
			})
			const formTop = this.forms[key].el.getBoundingClientRect().top
			const formHeight = this.forms[key].el.getBoundingClientRect().height
			const lastRowBottom = trs[location].getBoundingClientRect().bottom
			const maxHeight = `${lastRowBottom - formTop}px`
			this.forms[key].el.style.maxHeight = maxHeight
			this.forms[key].el.style.overflowY = 'hidden'

			if (this.forms[key].addedContinue) return

			const continueButton = document.createElement('button')
			continueButton.classList.add('button', 'button--solid-white', 'tripleseat-continue')
			continueButton.innerHTML = 'Continue'
			continueButton.addEventListener('click', () => {
				this.forms[key].el.style.maxHeight = `${formHeight}px`
				this.forms[key].clickedContinue = true
				continueButton.remove()
				window.setTimeout(() => {
					this.forms[key].el.style.maxHeight = 'unset'
					this.forms[key].el.style.overflowY = 'unset'
				}, 600)
			})
			this.forms[key].el.after(continueButton)
			this.forms[key].addedContinue = true
		})
	}

	// date picker rewrite DOM every time options change so we gotta do this
	async setDateSelects(key) {
		if (!this.forms[key].datePicker) return
		const parent = `[data-tripleseat="${key}"] #tripleseat_embed_form`
		const header = await this.waitForLoad(`${parent} .ui-datepicker-header`)
		header.querySelectorAll('select').forEach((select) => {
			styledSelect(`select[data-handler="${select.getAttribute('data-handler')}"]`)
		})
		const unload = await this.waitForUnload(parent, '.styled-select')
		if (unload) {
			this.setDateSelects(key)
		}
	}
}

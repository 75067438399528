import { hasTouchScreen } from './hasTouchScreen'

export const debounce = (fn, wait, device = 'all', immediate = false) => {
	if (device === 'touch' && !hasTouchScreen()) return
	if (device === 'mouse' && hasTouchScreen()) return
	let t
	if (immediate) {
		return (...args) => {
			if (!t) {
				fn.apply(this, args)
			}
			clearTimeout(t)
			t = setTimeout(() => {
				t = undefined
			}, wait)
		}
	}
	return (...args) => {
		clearTimeout(t)
		t = setTimeout(() => fn.apply(this, args), wait)
	}
}

export const debounceFollowing = (fn, wait) => {
	let t
}

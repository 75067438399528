document.querySelectorAll('[id^="Details-"] summary').forEach((summary) => {
	summary.setAttribute('role', 'button')
	summary.setAttribute('aria-expanded', summary.parentNode.hasAttribute('open'))

	if (summary.nextElementSibling.getAttribute('id')) {
		summary.setAttribute('aria-controls', summary.nextElementSibling.id)
	}

	summary.addEventListener('click', (event) => {
		event.currentTarget.setAttribute('aria-expanded', !event.currentTarget.closest('details').hasAttribute('open'))
	})

	summary.parentElement.addEventListener('keyup', onKeyUpEscape)
})

// Here run the querySelector to figure out if the browser supports :focus-visible or not and run code based on it.
try {
	document.querySelector(':focus-visible')
} catch (e) {
	focusVisiblePolyfill()
}

function focusVisiblePolyfill() {
	const navKeys = [
		'ARROWUP',
		'ARROWDOWN',
		'ARROWLEFT',
		'ARROWRIGHT',
		'TAB',
		'ENTER',
		'SPACE',
		'ESCAPE',
		'HOME',
		'END',
		'PAGEUP',
		'PAGEDOWN',
	]
	let currentFocusedElement = null
	let mouseClick = null

	window.addEventListener('keydown', (event) => {
		if (navKeys.includes(event.code.toUpperCase())) {
			mouseClick = false
		}
	})

	window.addEventListener('mousedown', (event) => {
		mouseClick = true
	})

	window.addEventListener(
		'focus',
		() => {
			if (currentFocusedElement) currentFocusedElement.classList.remove('focused')

			if (mouseClick) return

			currentFocusedElement = document.activeElement
			currentFocusedElement.classList.add('focused')
		},
		true
	)
}

function onKeyUpEscape(event) {
	if (event.code.toUpperCase() !== 'ESCAPE') return

	const openDetailsElement = event.target.closest('details[open]')
	if (!openDetailsElement) return

	const summaryElement = openDetailsElement.querySelector('summary')
	openDetailsElement.removeAttribute('open')
	summaryElement.setAttribute('aria-expanded', false)
	summaryElement.focus()
}

export function setExternalLinks() {
	const allLinks = document.querySelectorAll('a')
	allLinks?.forEach((link) => {
		if (link.href.charAt(0) === '#') return
		if (link.href.indexOf(window.location.host) === -1) {
			link.setAttribute('target', '_blank')
			link.setAttribute('rel', 'noopener noreferrer')
		}
	})
}

/*
 * Shopify Common JS
 *
 */
if (typeof window.Shopify == 'undefined') {
	window.Shopify = {}
}

Shopify.bind = function (fn, scope) {
	return function () {
		return fn.apply(scope, arguments)
	}
}

Shopify.setSelectorByValue = function (selector, value) {
	for (var i = 0, count = selector.options.length; i < count; i++) {
		var option = selector.options[i]
		if (value == option.value || value == option.innerHTML) {
			selector.selectedIndex = i
			return i
		}
	}
}

Shopify.addListener = function (target, eventName, callback) {
	target.addEventListener ? target.addEventListener(eventName, callback, false) : target.attachEvent('on' + eventName, callback)
}

Shopify.postLink = function (path, options) {
	options = options || {}
	var method = options['method'] || 'post'
	var params = options['parameters'] || {}

	var form = document.createElement('form')
	form.setAttribute('method', method)
	form.setAttribute('action', path)

	for (var key in params) {
		var hiddenField = document.createElement('input')
		hiddenField.setAttribute('type', 'hidden')
		hiddenField.setAttribute('name', key)
		hiddenField.setAttribute('value', params[key])
		form.appendChild(hiddenField)
	}
	document.body.appendChild(form)
	form.submit()
	document.body.removeChild(form)
}

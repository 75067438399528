import { removeTrapFocus, trapFocus } from '../helpers/trapFocus'

export class PickupAvailabilityDrawer extends HTMLElement {
	constructor() {
		super()

		this.onBodyClick = this.handleBodyClick.bind(this)

		this.querySelector('button').addEventListener('click', () => {
			this.hide()
		})

		this.addEventListener('keyup', (event) => {
			if (event.code.toUpperCase() === 'ESCAPE') this.hide()
		})
	}

	handleBodyClick(evt) {
		const target = evt.target
		if (target != this && !target.closest('pickup-availability-drawer') && target.id != 'ShowPickupAvailabilityDrawer') {
			this.hide()
		}
	}

	hide() {
		this.removeAttribute('open')
		document.body.removeEventListener('click', this.onBodyClick)
		document.body.classList.remove('overflow-hidden')
		removeTrapFocus(this.focusElement)
	}

	show(focusElement) {
		this.focusElement = focusElement
		this.setAttribute('open', '')
		document.body.addEventListener('click', this.onBodyClick)
		document.body.classList.add('overflow-hidden')
		trapFocus(this)
	}
}

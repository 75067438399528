export const videoObserver = (loader) => {
	let options = {
		root: null,
		rootMargin: '0px',
		threshold: 0.001,
	}

	let callback = (entries, observer) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				if (entry.target.getAttribute('data-loaded') !== 'true') {
					entry.target.setAttribute('autoplay', '')
					loader.triggerLoad(entry.target)
				}
				entry.target.play()
			} else {
				entry.target.pause()
			}
		})
	}

	let observer = new IntersectionObserver(callback, options)
	const videos = document.querySelectorAll('video:not([autoplay])')

	videos.forEach((video) => {
		observer.observe(video)
	})
}

import { removeTrapFocus, trapFocus } from '../helpers/trapFocus'

export class DetailsModal extends HTMLElement {
	constructor() {
		super()
		this.detailsContainer = this.querySelector('details')
		this.summaryToggle = this.querySelector('summary')
		this.closeButton = this.querySelector('button')

		this.detailsContainer.addEventListener('keyup', (event) => event.code.toUpperCase() === 'ESCAPE' && this.close())
		this.summaryToggle.addEventListener('click', this.onSummaryClick.bind(this))
		this.closeButton.addEventListener('click', (event) => this.close())
		this.querySelector('button[type="button"]').addEventListener('click', this.close.bind(this))

		this.summaryToggle.setAttribute('role', 'button')
	}

	isOpen() {
		return this.detailsContainer.hasAttribute('open')
	}

	onSummaryClick(event) {
		event.preventDefault()
		event.target.closest('details').hasAttribute('open') ? this.close() : this.open(event)
	}

	onBodyClick(event) {
		if (!this.contains(event.target) || event.target.classList.contains('details-modal__overlay')) this.close(false)
	}

	open(event) {
		this.onBodyClickEvent = this.onBodyClickEvent || this.onBodyClick.bind(this)
		event.target.closest('details').setAttribute('open', true)
		document.body.addEventListener('click', this.onBodyClickEvent)
		document.body.classList.add('overflow-hidden')

		try {
			trapFocus(this.detailsContainer.querySelector('[tabindex="-1"]'))
		} catch (e) {
			console.log('no tabindex')
		}
	}

	close(focusToggle = true) {
		removeTrapFocus(focusToggle ? this.summaryToggle : null)
		this.detailsContainer.removeAttribute('open')
		document.body.removeEventListener('click', this.onBodyClickEvent)
		document.body.classList.remove('overflow-hidden')
	}
}

import { getBackendRoute } from '../helpers/getBackendRoute'

export class KlaviyoEmail extends HTMLElement {
	constructor() {
		super()
		this.form = this.querySelector('form')
		this.list = this.form.getAttribute('data-list-id')
		this.submitButton = this.querySelector('[type="submit"]')
		this.emailInput = this.querySelector('[type="email"]')
		this.successMessage = this.form.getAttribute('data-success')
		this.route = `${getBackendRoute()}/api/client/emailSubscribe`
		this.form.addEventListener('submit', this.onSubmitHandler.bind(this))
	}

	onSubmitHandler(evt) {
		evt.preventDefault()
		if (this.submitButton.getAttribute('aria-disabled') === 'true') return
		if (this.emailInput.value.length < 1) return

		this.submitButton.setAttribute('aria-disabled', 'true')
		this.submitButton.classList.add('loading')

		const config = {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: this.emailInput.value,
				list: this.list,
			}),
		}

		fetch(this.route, config)
			.then((response) => response.json())
			.then((response) => {
				this.emailInput.value = ''
				if (response?.detail?.length) {
					this.emailInput.placeholder = 'This email address is invalid.'
				} else if (response.length) {
					this.emailInput.placeholder = this.successMessage
				} else {
					this.emailInput.placeholder = 'This email address is already subscribed!'
				}
			})
			.catch((e) => {
				console.error(e)
			})
			.finally(() => {
				this.submitButton.classList.remove('loading')
				this.submitButton.removeAttribute('aria-disabled')
			})
	}
}

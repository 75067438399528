import { SliderComponent } from './SliderComponent'

export class SlideshowComponent extends SliderComponent {
	constructor() {
		super()
		this.connectedCallback.bind(this)
	}

	connectedCallback() {
		console.log('henlo');
		this.animation = this.dataset.animation ? this.dataset.animation : 'slide'
		this.sliderControlWrapper = this.querySelectorAll('.slider-buttons')
		this.enableSliderLooping = true

		if (!this.sliderControlWrapper || !this.sliderControlWrapper.length) return

		this.sliderFirstItemNode = this.slider.querySelector('.slideshow__slide')
		if (this.sliderItemsToShow.length > 0) this.currentPage = 1

		this.sliderControlLinksArray = Array.from(this.querySelectorAll('.slider-buttons .slider-counter__link'))
		this.sliderControlLinksArray.forEach((link) => link.addEventListener('click', this.linkToSlide.bind(this)))
		this.slider.addEventListener('scroll', this.setSlideVisibility.bind(this))
		this.setSlideVisibility()

		if (this.slider.getAttribute('data-autoplay') === 'true') {
			console.log('set autoplay')
			this.setAutoPlay()
		}
	}

	setAutoPlay() {
		this.sliderAutoplayButton = this.querySelector('.slideshow__autoplay')
		this.autoplaySpeed = this.slider.dataset.speed * 1000

		this.sliderAutoplayButton.addEventListener('click', this.autoPlayToggle.bind(this))
		this.addEventListener('mouseover', this.focusInHandling.bind(this))
		this.addEventListener('mouseleave', this.focusOutHandling.bind(this))
		this.addEventListener('focusin', this.focusInHandling.bind(this))
		this.addEventListener('focusout', this.focusOutHandling.bind(this))

		this.play()
		this.autoplayButtonIsSetToPlay = true
	}

	onButtonClick(event) {
		super.onButtonClick(event)
		const isFirstSlide = this.currentPage === 1
		const isLastSlide = this.currentPage === this.sliderItemsToShow.length

		if (!isFirstSlide && !isLastSlide) return

		if (isFirstSlide && event.currentTarget.name === 'previous') {
			this.slideScrollPosition = this.slider.scrollLeft + this.sliderFirstItemNode.clientWidth * this.sliderItemsToShow.length
		} else if (isLastSlide && event.currentTarget.name === 'next') {
			this.slideScrollPosition = 0
		}
		this.slider.scrollTo({
			left: this.slideScrollPosition,
		})
	}

	update() {
		super.update()
		this.sliderControlButtons = this.querySelectorAll('.slider-counter__link')
		this.prevButton.removeAttribute('disabled')

		if (!this.sliderControlButtons.length) return

		this.sliderControlButtons.forEach((link) => {
			link.classList.remove('slider-counter__link--active')
			link.removeAttribute('aria-current')
		})

		this.sliderControlButtons[this.currentPage - 1].classList.add('slider-counter__link--active')
		this.sliderControlButtons[this.currentPage - 1].setAttribute('aria-current', true)

		const currentSlider = this.querySelectorAll(`.slider-counter__link[data-index="${this.currentPage - 1}"]`)
		if (!currentSlider || !currentSlider.length) return
		currentSlider.forEach((slider) => {
			slider.classList.add('slider-counter__link--active')
			slider.setAttribute('aria-current', true)
		})
	}

	autoPlayToggle() {
		this.togglePlayButtonState(this.autoplayButtonIsSetToPlay)
		this.autoplayButtonIsSetToPlay ? this.pause() : this.play()
		this.autoplayButtonIsSetToPlay = !this.autoplayButtonIsSetToPlay
	}

	focusOutHandling(event) {
		const focusedOnAutoplayButton = event.target === this.sliderAutoplayButton || this.sliderAutoplayButton.contains(event.target)
		if (!this.autoplayButtonIsSetToPlay || focusedOnAutoplayButton) return
		this.play()
	}

	focusInHandling(event) {
		const focusedOnAutoplayButton = event.target === this.sliderAutoplayButton || this.sliderAutoplayButton.contains(event.target)
		if (focusedOnAutoplayButton && this.autoplayButtonIsSetToPlay) {
			this.play()
		} else if (this.autoplayButtonIsSetToPlay) {
			this.pause()
		}
	}

	play() {
		console.log('play triggered');
		this.slider.setAttribute('aria-live', 'off')
		clearInterval(this.autoplay)
		this.autoplay = setInterval(this.autoRotateSlides.bind(this), this.autoplaySpeed)
	}

	pause() {
		this.slider.setAttribute('aria-live', 'polite')
		clearInterval(this.autoplay)
	}

	togglePlayButtonState(pauseAutoplay) {
		if (pauseAutoplay) {
			this.sliderAutoplayButton.classList.add('slideshow__autoplay--paused')
			this.sliderAutoplayButton.setAttribute('aria-label', window.accessibilityStrings.playSlideshow)
		} else {
			this.sliderAutoplayButton.classList.remove('slideshow__autoplay--paused')
			this.sliderAutoplayButton.setAttribute('aria-label', window.accessibilityStrings.pauseSlideshow)
		}
	}

	autoRotateSlides() {
		const slideScrollPosition =
			this.currentPage === this.sliderItems.length
				? 0
				: this.slider.scrollLeft + this.slider.querySelector('.slideshow__slide').clientWidth
		this.slider.scrollTo({
			left: slideScrollPosition,
		})
	}

	setSlideVisibility() {
		this.sliderItemsToShow.forEach((item, index) => {
			const linkElements = item.querySelectorAll('a')
			if (index === this.currentPage - 1) {
				if (linkElements.length)
					linkElements.forEach((button) => {
						button.removeAttribute('tabindex')
					})
				item.setAttribute('aria-hidden', 'false')
				item.removeAttribute('tabindex')
			} else {
				if (linkElements.length)
					linkElements.forEach((button) => {
						button.setAttribute('tabindex', '-1')
					})
				item.setAttribute('aria-hidden', 'true')
				item.setAttribute('tabindex', '-1')
			}
		})
	}

	linkToSlide(event) {
		event.preventDefault()
		const targetIndex = event.currentTarget.getAttribute('data-index')
			? parseInt(event.currentTarget.getAttribute('data-index'))
			: this.sliderControlLinksArray.indexOf(event.currentTarget)

		const slideScrollPosition = (this.slider.scrollLeft + this.sliderFirstItemNode.clientWidth) * (targetIndex + 1 - this.currentPage)
		this.slider.scrollTo({
			left: slideScrollPosition,
			behavior: this.animation === 'fade' ? 'instant' : 'smooth',
		})
	}
}

export const initResizeListeners = () => {
	const doc = document.documentElement
	const header = document.querySelector('.header-group-container')
	const appHeight = () => {
		doc.style.setProperty('--app-height', `calc(${window.innerHeight} * var(--ax))`)
		doc.style.setProperty('--header-height', `calc(${header.getBoundingClientRect().height} * .1rem)`)
	}
	window.addEventListener('orientationchange', appHeight)
	appHeight()
	const dynamicAppHeight = () => {
		doc.style.setProperty('--viewport-height', `${window.innerHeight / 10}rem`)
		doc.style.setProperty('--header-height', `calc(${header.getBoundingClientRect().height} * .1rem)`)
	}
	window.addEventListener('resize', dynamicAppHeight)
	dynamicAppHeight()
}

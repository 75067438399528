export const markAnimationComplete = (el) => {
	const duration = el.dataset.duration || el.dataset.animationDuration || 0
	const delay = el.dataset.delay || el.dataset.animationDelay || 0
	const wait = (parseFloat(duration) + parseFloat(delay)) * 1000 + 500
	window.setTimeout(() => {
		el.classList.add('section-animated-complete')
	}, wait)
}

export const animationObserver = () => {
	// standard animations
	const animationSections = document.querySelectorAll('[data-animate-section]:not([data-animate-on-center])')
	if (!animationSections) return
	let options = {
		root: null,
		rootMargin: '0px',
		threshold: 0.2,
	}

	let callback = (entries, observer) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				if (!entry.target.classList.contains('section-animated')) {
					entry.target.classList.add('section-animated')
					markAnimationComplete(entry.target)
				}
			}
		})
	}

	let observer = new IntersectionObserver(callback, options)

	animationSections.forEach((section) => {
		observer.observe(section)
	})
	// animate when element is more visible
	const animationOnCenterSections = document.querySelectorAll('[data-animate-section][data-animate-on-center]')
	if (!animationOnCenterSections) return
	let animationOnCenterOptions = {
		root: null,
		rootMargin: '0px',
		threshold: 0.5,
	}

	let animationOnCenterCallback = (entries, observer) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				if (!entry.target.classList.contains('section-animated')) {
					entry.target.classList.add('section-animated')
					markAnimationComplete(entry.target)
				}
			}
		})
	}

	let animationOnCenterObserver = new IntersectionObserver(callback, animationOnCenterOptions)

	animationOnCenterSections.forEach((section) => {
		animationOnCenterObserver.observe(section)
	})
}

export class QuantityInput extends HTMLElement {
	constructor() {
		super()
		this.input = this.querySelector('input')
		this.changeEvent = new Event('change', { bubbles: true })

		this.querySelectorAll('button').forEach((button) => button.addEventListener('click', this.onButtonClick.bind(this)))
	}

	onButtonClick(event) {
		event.preventDefault()
		const previousValue = this.input.value

		if (event.currentTarget.name === 'plus') {
			this.input.stepUp()
		} else if (event.currentTarget.name === 'minus') {
			this.input.stepDown()
		} else {
			return
		}
		if (previousValue !== this.input.value) this.input.dispatchEvent(this.changeEvent)
	}
}

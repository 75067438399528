import { removeTrapFocus, trapFocus } from './trapFocus'

export const styledSelect = (selector) => {
	// dom stuff
	const select = document.querySelector(selector)
	if (!select || select.classList.contains('styled-select')) return
	const parent = select.parentElement
	const options = select.querySelectorAll('option')
	if (!options) return

	select.classList.add('styled-select')
	const optionsEl = document.createElement('div')
	optionsEl.classList.add('styled-select__options')
	options.forEach((option) => {
		const newOption = document.createElement('button')
		newOption.classList.add('styled-select__option')
		newOption.innerHTML = option.innerHTML
		optionsEl.append(newOption)
		newOption.addEventListener('click', (e) => {
			optionsEl.classList.remove('active')
			select.value = option.value
			const event = new Event('change')
			select.dispatchEvent(event)
		})
	})
	optionsEl.style.top = `${select.getBoundingClientRect().height - 2}px`
	optionsEl.style.width = `${select.getBoundingClientRect().width}px`
	select.after(optionsEl)

	// helpers

	const bodyClickEventListener = (e) => {
		if (select.contains(e.target) || parent.contains(e.target)) return
		optionsEl.classList.remove('active')
		removeTrapFocus(e.target)
		document.removeEventListener('click', bodyClickEventListener, false)
	}

	const toggleSelect = (e) => {
		e.preventDefault()

		if (optionsEl.classList.contains('active')) {
			optionsEl.classList.remove('active')
			removeTrapFocus(select)
			document.removeEventListener('click', bodyClickEventListener, false)
		} else {
			optionsEl.classList.add('active')
			document.addEventListener('click', bodyClickEventListener, false)
			trapFocus(optionsEl)
		}
	}

	// init listeners

	select.addEventListener('mousedown', (e) => {
		toggleSelect(e)
	})
	select.addEventListener('keypress', (e) => {
		if (e.key !== 'Enter') return
		toggleSelect(e)
	})
}

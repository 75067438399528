import { debounce } from '../helpers/debounce'
import { fetchConfig } from '../helpers/fetchConfig'
import { trapFocus } from '../helpers/trapFocus'

export class CartItems extends HTMLElement {
	constructor() {
		super()

		this.lineItemStatusElement =
			document.getElementById('shopping-cart-line-item-status') || document.getElementById('CartDrawer-LineItemStatus')

		this.currentItemCount = Array.from(this.querySelectorAll('[name="updates[]"]')).reduce(
			(total, quantityInput) => total + parseInt(quantityInput.value),
			0
		)

		this.lineItems = this.getLineItems()

		this.beforeDebounce = (event) => {
			const update = this.getEventUpdate(event, false)
			this.enableLoading(update.index)
			this.immediateUpdate(update)
			this.updateTotal()
			this.debouncedOnChange(event)
		}

		this.debouncedOnChange = debounce((event) => {
			const update = this.getEventUpdate(event, false)
			this.updateTotal()
			this.onChange(update)
		}, 100)

		this.updateTotal.bind(this)

		this.addEventListener('change', this.beforeDebounce.bind(this))
	}

	onChange({ index, value, name }) {
		this.updateQuantity(index, value, name)
	}

	immediateUpdate({ index, value, name, key }) {
		const i = parseInt(index) - 1
		const qty = parseInt(value)
		if (this.lineItems.length <= i) return // make sure index exists in array
		const item = this.lineItems[i]
		if (key !== item.key) return // make sure selected index applies to the same key as the original event
		item.updateQuantity = qty
		if (item.updateQuantity < 1) {
			const items = this.querySelectorAll('.cart-item')
			items[i].classList.add('hidden')
		}
	}

	updateTotal() {
		this.total = this.lineItems.reduce((accumulator, object) => {
			return accumulator + object.price * object.updateQuantity
		}, 0)
	}

	getEventUpdate(event, debounced) {
		const update = {
			index: event.target.dataset.index,
			value: event.target.value,
			name: document.activeElement.getAttribute('name'),
			key: document.activeElement.getAttribute('data-key'),
		}
		if (debounced) return update
		const i = parseInt(update.index) - 1
		if (this.lineItems.length <= i) return { ...update, key: false }
		const item = this.lineItems[i]
		if (item.key !== update.key) return { ...update, key: false }
		return update
	}

	getLineItems() {
		const items = this.querySelectorAll('.cart-item')
		if (!items || !items.length) return []
		const mappedItems = Array.from(items).map((item, index) => {
			return this.getLineItem(item, index)
		})
		return mappedItems
	}

	getLineItem(item, index) {
		const variantId = item.getAttribute('data-variant')
		return {
			key: item.getAttribute('data-key'),
			index: index,
			currentQuantity: parseInt(item.getAttribute('data-current-quantity')),
			updateQuantity: parseInt(item.querySelector('[type="number"]').value),
			variantId,
			price: parseInt(item.getAttribute('data-price')),
		}
	}

	getSectionsToRender() {
		return [
			{
				id: 'main-cart-items',
				section: document.getElementById('main-cart-items').dataset.id,
				selector: '.js-contents',
			},
			{
				id: 'cart-icon-bubble',
				section: 'cart-icon-bubble',
				selector: '.shopify-section',
			},
			{
				id: 'cart-live-region-text',
				section: 'cart-live-region-text',
				selector: '.shopify-section',
			},
			{
				id: 'main-cart-footer',
				section: document.getElementById('main-cart-footer').dataset.id,
				selector: '.js-contents',
			},
		]
	}

	updateQuantity(line, quantity, name) {
		const body = JSON.stringify({
			line,
			quantity,
			sections: this.getSectionsToRender().map((section) => section.section),
			sections_url: window.location.pathname,
		})

		fetch(`${window.routes.cart_change_url}`, { ...fetchConfig(), ...{ body } })
			.then((response) => {
				return response.text()
			})
			.then((state) => {
				const parsedState = JSON.parse(state)
				this.classList.toggle('is-empty', parsedState.item_count === 0)
				const cartDrawerWrapper = document.querySelector('cart-drawer')
				const cartFooter = document.getElementById('main-cart-footer')

				if (cartFooter) cartFooter.classList.toggle('is-empty', parsedState.item_count === 0)
				if (cartDrawerWrapper) cartDrawerWrapper.classList.toggle('is-empty', parsedState.item_count === 0)

				this.getSectionsToRender().forEach((section) => {
					const elementToReplace =
						document.getElementById(section.id).querySelector(section.selector) || document.getElementById(section.id)

					elementToReplace.innerHTML = this.getSectionInnerHTML(parsedState.sections[section.section], section.selector)
				})

				this.updateLiveRegions(line, parsedState.item_count)
				const lineItem = document.getElementById(`CartItem-${line}`) || document.getElementById(`CartDrawer-Item-${line}`)
				if (lineItem && lineItem.querySelector(`[name="${name}"]`)) {
					cartDrawerWrapper
						? trapFocus(cartDrawerWrapper, lineItem.querySelector(`[name="${name}"]`))
						: lineItem.querySelector(`[name="${name}"]`).focus()
				} else if (parsedState.item_count === 0 && cartDrawerWrapper) {
					cartDrawerWrapper.close()
				} else if (document.querySelector('.cart-item') && cartDrawerWrapper) {
					trapFocus(cartDrawerWrapper, document.querySelector('.cart-item__name'))
				}
				this.disableLoading()
			})
			.catch((e) => {
				console.log('caught error on update', e)
				this.querySelectorAll('.loading-overlay').forEach((overlay) => overlay.classList.add('hidden'))
				this.disableLoading()
			})
			.finally(() => {
				this.lineItems = this.getLineItems()
				this.updateTotal()
			})
	}

	updateLiveRegions(line, itemCount) {
		if (this.currentItemCount === itemCount) {
			console.log('update failed')
		}

		this.currentItemCount = itemCount
		this.lineItemStatusElement.setAttribute('aria-hidden', true)

		const cartStatus = document.getElementById('cart-live-region-text') || document.getElementById('CartDrawer-LiveRegionText')
		cartStatus.setAttribute('aria-hidden', false)

		setTimeout(() => {
			cartStatus.setAttribute('aria-hidden', true)
		}, 1000)
	}

	getSectionInnerHTML(html, selector) {
		return new DOMParser().parseFromString(html, 'text/html').querySelector(selector).innerHTML
	}

	getSectionDOM(html, selector) {
		return new DOMParser().parseFromString(html, 'text/html').querySelector(selector)
	}

	enableLoading(line) {
		const mainCartItems = document.getElementById('main-cart-items') || document.getElementById('CartDrawer-CartItems')
		mainCartItems.classList.add('cart__items--disabled')

		const cartItemOverlays = this.querySelectorAll(`#CartItem-${line} .loading-overlay`)
		const cartDrawerItemOverlays = this.querySelectorAll(`#CartDrawer-Item-${line} .loading-overlay`)

		;[...cartItemOverlays, ...cartDrawerItemOverlays].forEach((overlay) => overlay.classList.remove('hidden'))

		const cartDrawerItems = this.querySelectorAll(`#CartDrawer-Item-${line}`)
		;[...cartDrawerItems].forEach((item) => item.classList.add('loading'))

		document.activeElement.blur()
		this.lineItemStatusElement.setAttribute('aria-hidden', false)
	}

	disableLoading() {
		const mainCartItems = document.getElementById('main-cart-items') || document.getElementById('CartDrawer-CartItems')
		mainCartItems.classList.remove('cart__items--disabled')

		const cartDrawerLoadingItems = this.querySelectorAll('.cart-item.loading')
		;[...cartDrawerLoadingItems].forEach((item) => item.classList.remove('loading'))
	}
}

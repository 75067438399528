import { CartDrawer } from './cart/CartDrawer'
import { CartItems } from './cart/CartItems'
import { CartRemoveButton } from './cart/CartRemoveButton'
import { CartDrawerItems } from './cart/CartDrawerItems'
import { DeferredMedia } from './global/DeferredMedia'

import { KlaviyoEmail } from './global/KlaviyoEmail'
import { CartItem } from './cart/CartItem'
import { StickyHeader } from './header/StickyHeader'
import { DetailsModal } from './global/DetailsModal'
import { FacetFiltersForm } from './global/FacetFiltersForm'
import { FacetRemove } from './global/FacetRemove'
import { PriceRange } from './global/PriceRange'
import { MediaGallery } from './product/MediaGallery'
import { PickupAvailability } from './product/PickupAvailability'
import { PickupAvailabilityDrawer } from './product/PickupAvailabilityDrawer'
import { ProductForm } from './product/ProductForm'
import { ProductModal } from './product/ProductModal'
import { ShareButton } from './product/ShareButton'
import { QuickAddModal } from './product/QuickAddModal'
import { QuantityInput } from './cart/QuantityInput'
import { ProductRecommendations } from './product/ProductRecommendations'
import { VariantRadios } from './product/VariantRadios'
import { VariantSelects } from './product/VariantSelects'
import { SliderComponent } from './product/SliderComponent'
import { SlideshowComponent } from './product/SlideshowComponent'
import { ModalOpener } from './global/ModalOpener'
import { MobileNav } from './header/MobileNav'
import {UcoastLottie} from "./global/UcoastLottie";

export const initialize = () => {
	// facets
	FacetFiltersForm.filterData = []
	FacetFiltersForm.searchParamsInitial = window.location.search.slice(1)
	FacetFiltersForm.searchParamsPrev = window.location.search.slice(1)
	customElements.define('facet-filters-form', FacetFiltersForm)
	FacetFiltersForm.setListeners()
	customElements.define('price-range', PriceRange)
	customElements.define('facet-remove', FacetRemove)
	// standard
	customElements.define('cart-remove-button', CartRemoveButton)
	customElements.define('cart-items', CartItems)
	customElements.define('cart-drawer', CartDrawer)
	customElements.define('cart-drawer-items', CartDrawerItems)
	customElements.define('deferred-media', DeferredMedia)
	customElements.define('variant-selects', VariantSelects)
	customElements.define('variant-radios', VariantRadios)
	customElements.define('quantity-input', QuantityInput)
	customElements.define('klaviyo-email', KlaviyoEmail)
	customElements.define('cart-item', CartItem)
	customElements.define('sticky-header', StickyHeader)
	customElements.define('details-modal', DetailsModal)
	customElements.define('mobile-nav', MobileNav)
	// dawn pdp
	customElements.define('media-gallery', MediaGallery)
	customElements.define('pickup-availability', PickupAvailability)
	customElements.define('pickup-availability-drawer', PickupAvailabilityDrawer)
	customElements.define('product-form', ProductForm)
	customElements.define('product-modal', ProductModal)
	customElements.define('quick-add-modal', QuickAddModal)
	customElements.define('share-button', ShareButton)
	customElements.define('product-recommendations', ProductRecommendations)
	customElements.define('slider-component', SliderComponent)
	customElements.define('slideshow-component', SlideshowComponent)
	customElements.define('modal-opener', ModalOpener)
	customElements.define('ucoast-lottie', UcoastLottie)
}

import { VariantSelects } from './VariantSelects'

export class VariantRadios extends VariantSelects {
	constructor() {
		super()
	}

	updateOptions() {
		const fieldsets = Array.from(this.querySelectorAll('fieldset'))
		this.options = fieldsets.map((fieldset) => {
			return Array.from(fieldset.querySelectorAll('input')).find((radio) => radio.checked).value
		})
	}
}
